import StackdriverErrorReporter from 'stackdriver-errors-js';

let Vue;

export default class StackdriverErrorsPlugin {
  constructor(kauth) {
    this.kauth = kauth;
    this._errorHandler = null;
  }

  async setup() {
    this._errorHandler = new StackdriverErrorReporter();
    this._errorHandler.start({
      key: process.env.VUE_APP_GOOGLE_ERROR_API_KEY,
      projectId: 'msi-epwc',
      service: 'witko-portal',
      reportUncaughtExceptions: true,
      reportUnhandledPromiseRejections: true,
      disabled: process.env.NODE_ENV !== 'production'
    });

    await this.kauth.getValidToken();
    this._errorHandler.setUser(this.kauth.idTokenParsed.email);
  }

  report(...args) {
    this._errorHandler.report(...args);
  }

  install(_Vue) {
    if (Vue && _Vue === Vue) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(
          '[StackdriverErrorsPlugin] already installed. Vue.use(StackdriverErrorsPlugin) should be called only once.'
        );
      }
      return;
    }

    Vue = _Vue;
    Vue.prototype.$stackdriverErrors = this;
    if (process.env.NODE_ENV === 'production') {
      Vue.config.errorHandler = err => this.report(err);
    }
  }
}